import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const DataProtectionDeclaration = () => {
  const handleReject = () => {
    localStorage.setItem("cookiesAccepted", false)
    const wiredminds = document.getElementById("wm")
    const linkedinScript = document.getElementById("linkedin-insight");

    if (linkedinScript) {
      linkedinScript.parentNode.removeChild(linkedinScript);
    }

    if (wiredminds && wiredminds.parentNode) {
      window["wiredminds"].optOut()
      wiredminds.parentNode.removeChild(wiredminds)
    }

    alert("You are now excluded from tracking.")
  }

  return (
    <React.Fragment>
      <PageTitle title={"Data protection declaration"} />
      <Layout>
        <div className="imprint-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Data protection declaration</a>
              </li>
            </ul>
          </div>
          <div className="data-protection">
            <h1>Data protection declaration</h1>
            <div className="data-protection__block">
              <div className="text">
                <p>
                  The protection of your data is important to Cyber Trust
                  Services GmbH (hereinafter “CTS”). We therefore process your
                  data exclusively based on the statutory provisions in
                  accordance with the EU General Data Protection Regulation
                  (GDPR). In this data protection declaration, we inform you
                  about the most important aspects of data processing within our
                  company.
                </p>
                <div className="name">Responsibilities</div>
                <p>
                  The person responsible in accordance with Article 4 (7) of the
                  EU General Data Protection Regulation (GDPR) is
                </p>
                <ul>
                  <li>Cyber Trust Services GmbH</li>
                  <li>Wienerbergstraße 11/12A A-1100 Vienna</li>
                  <li>
                    You can contact our data protection officer at:{" "}
                    <a href="mailto:datenschutz@cyber-trust.at">
                      datenschutz@cyber-trust.at
                    </a>
                  </li>
                </ul>
                <div className="name">
                  I. Collection of data when using our website
                </div>
                <p>
                  Our website uses counting pixel technology provided by
                  WiredMinds GmbH (www.wiredminds.de) to analyze visitor
                  behavior. In connection with this, the IP address of the
                  visitor is processed. The processing occurs only for the
                  purpose of collecting company based information such as
                  company name, for example. IP addresses of natural persons are
                  excluded from any further processing by means of a whitelist.
                  An IP address is not stored in LeadLab under any
                  circumstances. While processing data, it is our outmost
                  interest to protect the rights of natural persons. Our
                  interest in processing data is based on Article 6(1)(f) GDPR.
                  At no time is it possible to draw conclusions from the
                  collected data on an identifiable person. WiredMinds GmbH uses
                  this information to create{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    anonymized
                  </span>{" "}
                  usage profiles of the visit behavior on our website. Data
                  obtained during this process is not used to personally
                  identify visitors of our website. Furthermore no data is
                  forwarded to advertisement companies. Via the LinkedIn Insight Tag 
                  Manager we get the information who comes to our website from LinkedIn. 
                  Only the job title and company name are transferred and no names of persons.
                </p>
                <ul>
                  <li>
                    <a onClick={handleReject}>Opt-Out</a>
                  </li>
                </ul>
                <div className="name">
                  II. Data collection and use when contacting us and using the
                  request form
                </div>
                <p>
                  When you contact us by e-mail or via our request form, the
                  data you provide (this can include: your e-mail address, your
                  name, your telephone number or other information you have
                  provided) will be stored by us in order to be able to answer
                  your questions. To be able to process your label request, we
                  collect and save the following data: first name, last name,
                  email address, telephone number, company, role, industry and
                  address. The legal basis for this processing is contract
                  initiation and fulfillment in accordance with Article 6
                  Paragraph 1 lit b GDPR.
                </p>
                <div className="name">
                  III. Data deletion and storage duration
                </div>
                <p>
                  The data is only stored for as long as it is necessary to
                  fulfill the contract. The data will then be deleted. In
                  addition, there may be statutory retention requirements, e.g.
                  according to the Corporate Code (UGB) and the Federal Fiscal
                  Code (BAO). After the statutory retention periods have
                  expired, we will immediately delete your personal data from
                  our databases (both digitally and physically).
                </p>
                <div className="name">
                  IV. Transmission of data to processors
                </div>
                <p>
                  CTS uses third parties for processing, especially in the IT
                  area. These process the data as so-called contract processors,
                  i.e. on the basis of a written contract in accordance with
                  Article 28 GDPR, in which the details of data processing on
                  behalf of CTS are regulated and in which the contract
                  processor undertakes to handle the data carefully. Such order
                  processing exists, for example, when CTS stores data in an
                  external data center. The processors are carefully selected by
                  CTS, paying special attention to the suitability of the
                  technical and organisational measures they have taken, and
                  their compliance is checked. CTS generally processes the data
                  in Austria and in the European Union.
                </p>
                <div className="name">V. Data transfer to third parties</div>
                <p>
                  We do not share personal information with third parties unless
                  this is necessary for our legitimate business needs and the
                  performance of a contract, and / or when required or permitted
                  by law or professional standards. The necessary contact
                  details are passed on to Nimbusec GmbH for the creation of the
                  cyber risk rating required for the award of the label. This is
                  necessary for the fulfillment of the contract. Reference is
                  also made to the data protection declaration of Nimbusec GmbH.
                </p>
                <div className="name">VI. Data processing security</div>
                <p>
                  We use suitable technical and organisational security measures
                  to protect your data against accidental or deliberate
                  manipulation, partial or complete loss, destruction or against
                  unauthorized access by third parties (e.g. TLS encryption for
                  our website), taking into account the state of the art and
                  implementation costs and the nature, scope, context and
                  purpose of the processing as well as the existing risks of a
                  data breach (including its probability and effects) for the
                  data subject. Our security measures are continuously improved
                  in line with technological developments.
                </p>
                <div className="name">VII. Your rights</div>
                <p>
                  You have the following rights vis-à-vis us regarding your
                  personal data:
                </p>
                <ul>
                  <li>Right to information,</li>
                  <li>Right to correction or deletion,</li>
                  <li>Right to restriction of processing,</li>
                  <li>Right to object to processing,</li>
                  <li>Right to data portability.</li>
                </ul>
                <p>
                  You also have the right to lodge a complaint with the
                  responsible supervisory authority (in Austria, the data
                  protection authority based in Vienna). The data protection
                  authority can be reached at the following address:
                </p>
              </div>
              <div className="address">
                <ul>
                  <li>Austrian data protection authority</li>
                  <li>Barichgasse 40-42 1030 Vienna</li>
                  <li>Telefon: +43 1 52 152-0</li>
                  <li>
                    E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a>
                  </li>
                </ul>
              </div>
              <div className="text">
                <div className="name">
                  VIII. Update of the data protection declaration
                </div>
                <p>
                  We reserve the right to make changes to this data protection
                  declaration at any time. The privacy policy is updated
                  regularly, and all changes are published automatically.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {/* Bottom-right Scroll-UP button */}
      {/* <a
        className="main-button__top js-scroll-top"
        style={{ opacity: "1" }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span></span>
        <svg
          preserveAspectRatio="none"
          className="progress"
          viewBox="0 0 110 110"
        >
          <circle className="circle-bg" r="50" cx="55" cy="55"></circle>
          <circle
            className="circle-go"
            r="50"
            cx="55"
            cy="55"
            style={{
              strokeDasharray: "314.159px",
              strokeDashoffset: "119.68px",
            }}
          ></circle>
        </svg>
      </a> */}
    </React.Fragment>
  )
}

export default DataProtectionDeclaration
